import API from './data.api.ts'
import { Completer } from 'o365-utils';

interface IO365Token {
    expires_in: number;
    refresh_token_expires_in: number;
    access_token: string;
    refresh_token: string;
}

class O365TokenHandler {
    private token: IO365Token | null;
    private completer: Completer<undefined> | null = null;
    private tokenRequestCompleter: Completer<undefined> | null = null;

    private get hasToken(): boolean {
        return this.token !== null && typeof this.token === 'object';
    }

    private get isTokenExpired(): boolean {
        if (!this.hasToken) {
            return false;
        }

        const currentTime = Math.floor(Date.now() / 1000);

        return currentTime > this.token!.expires_in;
    } 

    constructor() {
        this.token = null;
    }

    public async initializeToken(): Promise<void> {
        if (this.completer !== null) {
            return;
        }

        this.completer = new Completer();

        if (this.hasToken && this.isTokenExpired) {
            await this.refreshToken();
        } else if (!this.hasToken) {
            await this.createToken();
        }

        this.completer.complete(undefined);
    }

    public async getToken(): Promise<IO365Token | null> {
        if (this.completer === null || this.completer.state !== 'Fulfilled') {
            return null;
        }

        if (this.tokenRequestCompleter !== null) {
            await this.tokenRequestCompleter.promise;
        }

        if (!this.hasToken) {
            this.tokenRequestCompleter = new Completer<undefined>();
            
            await this.createToken();

            this.tokenRequestCompleter.complete(undefined);
            this.tokenRequestCompleter = null;
        } else if (this.isTokenExpired) {
            this.tokenRequestCompleter = new Completer<undefined>();
            
            await this.refreshToken();
            
            this.tokenRequestCompleter.complete(undefined);
            this.tokenRequestCompleter = null;
        }

        return this.token;
    }

    private async createToken(): Promise<void> {
        try {
            const refreshTokenResponse: Response = await API.request({
                requestInfo: '/api/token/create',
                method: 'POST',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-NT-API': 'true',
                    'ClientId': 'Omega 365 Web Client',
                    'IncludeCookie': 'true',
                }),
                credentials: 'same-origin',
                body: JSON.stringify({}),
                responseBodyHandler: API.ResponseHandler.Raw,
                responseStatusHandler: false,
                skipJwtCheck: true
            });

            if (refreshTokenResponse.status === 200) {
                this.token = await refreshTokenResponse.json();
            } else {
                this.token = null;
            }
        } catch (reason) {
            this.token = null;
        }
    }

    private async refreshToken(): Promise<void> {
        if (this.token === null) {
            return;
        }

        try {
            const refreshTokenResponse: Response = await API.request({
                requestInfo: '/api/token/refresh',
                method: 'POST',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-NT-API': 'true'
                }),
                credentials: 'same-origin',
                body: JSON.stringify({}),
                responseBodyHandler: API.ResponseHandler.Raw,
                responseStatusHandler: false,
                skipJwtCheck: true
            });

            if (refreshTokenResponse.status === 200) {
                this.token = await refreshTokenResponse.json();
            } else {
                this.token = null;
            }
        } catch (reason) {
            this.token = null;
        }
    }
}

const o365TokenHandler = new O365TokenHandler();

export { o365TokenHandler as default, o365TokenHandler };